import React from 'react';
import './index.css';

const profileData = {
  name: "David Gonzalez",
  title: "Full-Stack Developer",
  location: "Florida, US",
  avatar: "/blue-shirt-small.png",
  skills: ["React", "Node.js", "MongoDB", "Express.js", "Azure", "AWS", "Javascript", "Back-End", "Front-End"],
  projects: [
    {
      name: "Dragex",
      description: "Currency and crypto exchange bot for discord. Also has features to check stock information/price.",
    },
    {
      name: "Donut Holez NFT",
      description: "Generative NFT collection of 10,000 donuts. Utility includes a portion of the funds going to hunger charities for people in need.",
    },
  ],
  contact: [
    { 
      label: "Email:",
      link: "mailto:dgonzalez1992@outlook.com",
      linkLabel: "dgonzalez1992@outlook.com"
    },
    { 
      label: "Phone:",
      link: "tel:+17863741534",
      linkLabel: "+1 (786) 374-1534"
    },
    { 
      label: "LinkedIn:",
      link: "https://www.linkedin.com/in/david-gonzalez-dev/",
      linkLabel: "david-gonzalez-dev"
    },
    { 
      label: "Github:",
      link: "https://github.com/dgz9",
      linkLabel: "dgz9"
    },
    { 
      label: "Discord:",
      link: "#",
      linkLabel: "dgz#9999"
    }
  ],
};

function App() {
  const { name, title, location, avatar, skills, contact } = profileData;

  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-400 via-purple-500 to-pink-400 flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-3xl">
        <div className="text-center">
          <img
            src={avatar}
            alt="Your Profile"
            className="w-32 h-32 mx-auto rounded-full border-4 border-blue-400 shadow-md"
          />
          <h1 className="text-4xl font-bold mt-4 mb-2">{name}</h1>
          <p className="text-gray-600">{title} | {location}</p>
        </div>
        <div className="mt-8">
          <h2 className="font-bold text-2xl mb-2">Skills</h2>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-2 mb-6">
            {skills.map((skill, index) => (
              <div key={index} className="bg-blue-100 text-blue-700 px-3 py-1 rounded hover:bg-blue-200 transition duration-200">{skill}</div>
            ))}
          </div>
          { //<h2 className="font-bold text-2xl mb-2">Projects</h2>
          // {projects.map((project, index) => (
            // <div key={index} className="mb-4">
              // <h3 className="font-bold mb-1">{project.name}</h3>
              // <p className="text-gray-600 max-w-sm overflow-hidden leading-tight" style={{ display: "-webkit-box", WebkitLineClamp: "5", WebkitBoxOrient: "vertical" }}>{project.description}</p>
            // </div>
          // ))}
          }
        </div>
        <div className="mt-4">
          <h2 className="font-bold text-2xl mb-2">Contact</h2>
          <ul className="list-disc list-inside">
          {contact.map((contact, index) => (
            <li key={index}>{contact.label} <a href={contact.link} className="text-blue-500 hover:text-blue-700 transition duration-200">{contact.linkLabel}</a></li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default App;

